import KakaoBtn from '../../components/Login/KakaoBtn';
import NaverBtn from '../../components/Login/NaverBtn';
import GoogleBtn from '../../components/Login/GoogleBtn';

function LoginMain() {
  return (
    <div>
      <div className='flex-col justify-start items-start mx-auto w-80 border-b border-black border-opacity-40 mb-5'>
        <img
          src='/assets/icon/10duk_newlogo_fin.svg'
          alt='logo'
          className='max-h-6 my-3'
        />
        <div className=' text-sm text-main font-bold text-opacity-[70%] pb-2'>
          소셜 로그인
        </div>
      </div>
      <div className='my-3'>
        <NaverBtn />
      </div>
      <div className='my-3'>
        <KakaoBtn />
      </div>
      <div className='my-3'>
        <GoogleBtn />
      </div>
    </div>
  );
}

export default LoginMain;
