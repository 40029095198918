import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Components
import Layout from './components/Layout/Layout';
import MobileLayout from './components/Layout/MobileLayout';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics';
import LoginModal from './components/Modal/LoginModal';

// Pages
import MainPage from './pages/MainPage/MainPage';
import MobileMainPage from './pages/MainPage/MobileMainPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import LoginRedirectPage from './pages/LoginRedirectPage/LoginRedirectPage';
import Profilepage from './pages/ProfilePage/Profilepage';
import MobileProfilePage from './pages/ProfilePage/MobileProfilePage';
import DetailPage from './pages/DetailPage/DetailPage';
import MobileDetailPage from './pages/DetailPage/MobileDetailPage';
import CreatePostPage from './pages/CreatePostPage/CreatePostPage';
import MobileCreatePostPage from './pages/CreatePostPage/MobileCreatePostPage';
import BoardMainPage from './pages/BoardMainPage/BoardMainPage';
import MobileBoardMainPage from './pages/BoardMainPage/MobileBoardMainPage';
import CreateBoardPostPage from './pages/CreateBoardPostPage/CreateBoardPostPage';
import MobileCreateBoardPostPage from './pages/CreateBoardPostPage/MobileCreateBoardPostPage';
import VoteBoardPage from './pages/VoteBoardPage/VoteBoardPage';
import MobileVoteBoardPage from './pages/VoteBoardPage/MobileVoteBoardPage';
import HotDukPage from './pages/HotDukPage/HotDukPage';
import MobileHotDukPage from './pages/HotDukPage/MobileHotDukPage';
import RankDukPage from './pages/RankDukPage/RankDukPage';
import MobileRankDukPage from './pages/RankDukPage/MobileRankDukPage';
import SearchResultPage from './pages/SearchResultPage/SearchResultPage';
import MobileSearchResultPage from './pages/SearchResultPage/MobileSearchResultPage';
import VoteBoardDetailPage from './pages/VoteBoardDetailPage/VoteBoardDetailPage';
import MobileVoteBoardDetailPage from './pages/VoteBoardDetailPage/MobileVoteBoardDetailPage';
import TotalPostPage from './pages/TotalPostPage/TotalPostPage';
import MobileTotalPostPage from './pages/TotalPostPage/MobileTotalPostPage';
import LoginPage from './pages/LoginPage/LoginPage';
import LocalLoginPage from './pages/LoginPage/LocalLoginPage';
import PolicyPage from './pages/PolicyPage/PolicyPage';
import TermsOfUsePage from './pages/TermsOfUsePage/TermsOfUsePage';
import AnnouncementDetailPage from './pages/AnnouncementDetailPage/AnnouncementDetailPage';
import MobileAnnouncementDetailPage from './pages/AnnouncementDetailPage/MobileAnnouncementDetailPage';
import IntegratedAnnouncementDetailPage from './pages/IntegratedAnnouncementDetailPage/IntegratedAnnouncementDetailPage';
import MobileIntegratedAnnouncementDetailPage from './pages/IntegratedAnnouncementDetailPage/MobileIntegratedAnnouncementDetailPage';
import EditPostPage from './pages/EditPostPage/EditPostPage';

// Constants and Types
import { useAppSelector, useAppDispatch } from './constants/types';
import { axiosFileInstance, axiosInstance } from './apis/instance';
import {
  updateLoginStatus,
  updateAccessToken,
  updateAdminAdminStatus,
  updateAdminStatus,
} from './store/authReducer';
import {
  setIsTwitterReferrer,
  setRedirectUrl,
} from './store/twitterReferrerSlice';
import MobileEditPostPage from './pages/EditPostPage/MobileEditPostPage';
import AdminRoutes from './AdminRoutes';

// import PrivateRouteWrapper from "./PrivateRoute";

const PrivateRoute: React.FC = () => {
  const isTwitterReferrer = useAppSelector(
    (state) => state.twitterReferrer.isTwitterReferrer
  );
  const isAuthenticated = useAppSelector((state) => state.auth.isLogin);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get('source');
  searchParams.delete('source');
  const redirectUrl = `${location.pathname}?${searchParams.toString()}`;

  return isTwitterReferrer && !isAuthenticated ? (
    <>
      <Navigate to={`${redirectUrl}`} />
    </>
  ) : (
    <Outlet />
  );
};

function App() {
  const location = useLocation();
  GoogleAnalytics();

  const accessToken = useAppSelector((state) => state.auth.accessToken);
  // 헤더 디폴트 설정
  if (accessToken) {
    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessToken}`;
    axiosFileInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${accessToken}`;
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // 토큰 갱신
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response: { status, data },
      } = error;
      const originalRequest = config;
      if (status === 401 && data.message === 'Access Token is expired') {
        try {
          // 토큰 갱신 요청
          const res = await axiosInstance.post<any>('/auth/refresh');
          const newAccessToken = res.data.accessToken;
          dispatch(updateAccessToken(newAccessToken));
          // 헤더에 새로운 토큰으로 변경 후 재요청
          axiosInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${newAccessToken}`;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (err) {
          // 토큰 갱신 실패시 임의 로그아웃 처리
          dispatch(updateLoginStatus(false));
          dispatch(updateAccessToken(''));
          navigate('/');
        }
      }
      return Promise.reject(error);
    }
  );

  // Formdata 토큰 갱신

  axiosFileInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response: { status, data },
      } = error;
      const originalRequest = config;
      if (status === 401 && data.message === 'Access Token is expired') {
        try {
          // 토큰 갱신 요청
          const res = await axiosFileInstance.post<any>('/auth/refresh');
          const newAccessToken = res.data.accessToken;
          dispatch(updateAccessToken(newAccessToken));
          // 헤더에 새로운 토큰으로 변경 후 재요청
          axiosFileInstance.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${newAccessToken}`;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosFileInstance(originalRequest);
        } catch (err) {
          // 토큰 갱신 실패시 임의 로그아웃 처리
          dispatch(updateLoginStatus(false));
          dispatch(updateAccessToken(''));
          navigate('/');
        }
      }
      return Promise.reject(error);
    }
  );

  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const routeHistory = useAppSelector((state) => state.auth.routeHistory);
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);

  //관리자 권한 초기화
  useEffect(() => {
    if (!isLogin) {
      dispatch(updateAdminStatus(false));
      dispatch(updateAdminAdminStatus(false));
    }
  }, [isLogin, isAdmin, dispatch]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      (currentPath.includes('/post/create') ||
        currentPath.includes('/post/edit') ||
        currentPath.includes('/profile') ||
        currentPath.includes('/board/create')) &&
      !isLogin
    ) {
      alert('로그인이 필요한 서비스입니다.');
      navigate('/login');
    }
    if (currentPath.includes('/admin') && !isAdmin) {
      alert('관리자만 접근 가능한 페이지입니다.');
      navigate('/');
    }
  }, [
    dispatch,
    isLogin,
    location,
    navigate,
    routeHistory,
    isAdmin,
    accessToken,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');
    dispatch(setIsTwitterReferrer(source === 'link'));

    if (source === 'link' && !isLogin) {
      searchParams.delete('source');
      const redirectUrl = `${location.pathname}?${searchParams.toString()}`;
      dispatch(setRedirectUrl(redirectUrl));
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [dispatch, location, isLogin]);

  const isPc = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const isTwitterReferrer = useAppSelector(
    (state) => state.twitterReferrer.isTwitterReferrer
  );

  const layoutComponent = isPc ? <Layout /> : <MobileLayout />;
  const mainPageComponent = isPc ? <MainPage /> : <MobileMainPage />;
  const boardMainPageComponent = isPc ? (
    <BoardMainPage />
  ) : (
    <MobileBoardMainPage />
  );
  const hotDukPageComponent = isPc ? <HotDukPage /> : <MobileHotDukPage />;
  const rankDukPageComponent = isPc ? <RankDukPage /> : <MobileRankDukPage />;
  const totalPostPageComponent = isPc ? (
    <TotalPostPage />
  ) : (
    <MobileTotalPostPage />
  );
  const detailPageComponent = isPc ? <DetailPage /> : <MobileDetailPage />;
  const createPostPageComponent = isPc ? (
    <CreatePostPage />
  ) : (
    <MobileCreatePostPage />
  );
  const editPostPageComponent = isPc ? (
    <EditPostPage />
  ) : (
    <MobileEditPostPage />
  );

  const announcementDetailPageComponent = isPc ? (
    <AnnouncementDetailPage />
  ) : (
    <MobileAnnouncementDetailPage />
  );

  const integratedAnnouncementDetailPageComponent = isPc ? (
    <IntegratedAnnouncementDetailPage />
  ) : (
    <MobileIntegratedAnnouncementDetailPage />
  );

  const profilePageComponent = isPc ? <Profilepage /> : <MobileProfilePage />;
  const voteBoardPageComponent = isPc ? (
    <VoteBoardPage />
  ) : (
    <MobileVoteBoardPage />
  );
  const voteBoardDetailPageComponent = isPc ? (
    <VoteBoardDetailPage />
  ) : (
    <MobileVoteBoardDetailPage />
  );

  const searchResultPageComponent = isPc ? (
    <SearchResultPage />
  ) : (
    <MobileSearchResultPage />
  );

  const createBoardPostPageComponent = isPc ? (
    <CreateBoardPostPage />
  ) : (
    <MobileCreateBoardPostPage />
  );
  return (
    <>
      <div className={`${isTwitterReferrer && !isLogin ? 'blur' : ''}`}>
        <Routes>
          <Route path='/' element={layoutComponent}>
            <Route index element={totalPostPageComponent} />{' '}
            {/*mainPageComponent*/}
            <Route path='/policy' element={<PolicyPage />} />
            <Route path='/terms-of-use' element={<TermsOfUsePage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route
              path='/login/adsfaweg5q656461384q56we68f64'
              element={<LocalLoginPage />}
            />
            <Route
              path='/login/auth2/naver'
              element={<LoginRedirectPage isNaver />}
            />
            <Route
              path='/login/auth2/kakao'
              element={<LoginRedirectPage isKakao />}
            />
            <Route
              path='/login/auth2/google'
              element={<LoginRedirectPage isGoogle />}
            />
            <Route path='/profile' element={profilePageComponent} />
            <Route
              path='/search/:pageNumber?'
              element={searchResultPageComponent}
            />
            <Route
              path='/post/totalpost/:pageNumber?'
              element={totalPostPageComponent}
            />
            <Route
              path='/post/hotduk/:pageNumber?'
              element={hotDukPageComponent}
            />
            <Route
              path='/post/rankduk/:pageNumber?'
              element={rankDukPageComponent}
            />
            <Route
              path='/board/:boardNumber'
              element={boardMainPageComponent}
            />
            {/* <Route path="/post/:boardNumber/:postId" element={<PrivateRoute />}> */}
            <Route
              path='/post/:boardNumber/:postId'
              element={detailPageComponent}
            />
            <Route
              path='/announcement/:boardNumber/:postId'
              element={announcementDetailPageComponent}
            />
            <Route
              path='/announcement/:postId'
              element={integratedAnnouncementDetailPageComponent}
            />
            <Route
              path='/post/create/:boardNum'
              element={createPostPageComponent}
            />
            <Route path='/post/edit/:postId' element={editPostPageComponent} />
            <Route
              path='/board/create'
              element={createBoardPostPageComponent}
            />
            <Route
              path='/board/voteboard/:pageNumber?'
              element={voteBoardPageComponent}
            />
            <Route
              path='/board/voteboard/voteboardpost/:voteBoardPostId'
              element={voteBoardDetailPageComponent}
            />
            <Route path='/admin/page/*' element={<AdminRoutes />} />
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </div>
      {isTwitterReferrer && !isLogin && <LoginModal />}
    </>
  );
}

export default App;
